import { ApiDataItem } from '../../../types';

export const TicketingApiUrls = {
  Tenant: {
    Create: '/api/services/app/TenantTicket/Create',
    AddMessage: '/api/services/app/TenantTicket/AddMessage',
    AddAttachment: '/api/services/app/TenantTicket/AddAttachment',
    GetAll: '/api/services/app/TenantTicket/GetAll',
    Get: '/api/services/app/TenantTicket/Get',
  },
  Title: {
    GetAll: '/api/services/app/Title/GetAll',
  },
};

export const TicketingApiDataMap: Map<string, ApiDataItem> = new Map();
