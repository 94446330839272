import Axios from 'axios';

import { ApiDataItem, AxiosInstanceData2 } from '../../types';
import { urlWithQuery } from '../../utils/helpers';

const FileManagerApiUrls = {
  File: {
    Upload: '/api/services/app/File/Upload',
    Download: '/api/services/app/File/Download',
    Get: '/api/services/app/File/Get',
  },
};

const FileManagerApiDataMap: Map<string, ApiDataItem> = new Map();

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_FileManagerApiBaseUrl;

export const FileManagerHttp = {
  uploadFile: (payload: {
    fileName: string;
    // Base64
    file: string;
    type: 'Hotel' | 'Airline' | 'Bank' | 'Other' | 'Pdf' | 'Room' | 'Bus' | 'HotelIntro' | 'Setting' | 'HotelGallery' | 'Tickets';
  }) => {
    return axiosInstance.post(FileManagerApiUrls.File.Upload, payload);
  },

  // deleteFile: (payload: { Id: string }) => {
  //   const url = urlWithQuery(FileManagerApiUrls.File.Get, payload);
  //   return axiosInstance.delete(url);
  // },

  downloadFile: (payload: { id: string }) => {
    return axiosInstance.post(FileManagerApiUrls.File.Download, payload);
  },

  getFile: (payload: { Id: string }) => {
    const url = urlWithQuery(FileManagerApiUrls.File.Get, payload);
    return axiosInstance.get(url);
  },
};

export const FileManagerAxiosData: AxiosInstanceData2 = {
  instance: axiosInstance,
  DataMap: {
    MapObject: FileManagerApiDataMap,
    Urls: FileManagerApiUrls,
  },
};

export type FileManagerUploadRs = {
  fullPath: string;
  uniqKey: string;
  path: string;
  mimeType: string;
  filename: string;
  titleAttribute: string;
  altAttribute: string;
  id: number;
};

export type FileManagerDownloadRs = {
  fileName: string;
  fileType: string;
  fileToken: string;
};
